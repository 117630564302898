<!-- 班级登记管理-更多 -->
<template>
    <div class="ClassRegisterMore">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">班级登记管理-更多</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- tab切换 -->
        <div class="" style="">
            <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
             @click="toTab(index)" :class="tab==index?'active':''">
                {{item}}
            </button>
        </div>
        <el-divider v-if="tab==0"></el-divider>
        <el-divider v-if="tab==4"></el-divider>
        <!-- 单据说明 -->
        <div class="" v-if="tab==0" :key="0">
            <el-form ref="form" :model="form" inline class="form" label-width="120px">
                <el-form-item label="单据说明">
                    <el-input  placeholder="" type="textarea" rows="5"
                        class="textArea-width" disabled>
                    </el-input>
                </el-form-item>
            </el-form>
            <!-- <el-table :data="billData" border stripe style="width:97.9%;flex: 1;" height="650">
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" sortable label="学生号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="报名日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="交费日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="退(转)学日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="学籍类" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="报名上课次数" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="优惠率(%)" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="让利" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="实付学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="其中学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="杂费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="实收学杂费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="定金" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="退(转)费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="学费标准" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="授课次数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="转入金额" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="学费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="经办人员" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="标准每课次学费" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="实际每课次学费" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="说明" align="center" width="300">
                </el-table-column>
            </el-table> -->
        </div>
        <!-- 排课表 -->
        <div class="" v-if="tab==1" :key="1">
            <el-table :data="scheduleData" border stripe style="width:97.9%;flex: 1;" height="650">
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="course_type" sortable label="课类" align="center" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_type==1">正常课</span>
                        <span v-else-if="scope.row.course_type==2">补课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="course_status" sortable label="状态" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.course_status==1">可用</span>
                        <span v-else-if="scope.row.course_status==2">取消</span>
                        <span v-else-if="scope.row.course_status==3">已授课</span>
                    </template>
                </el-table-column>
                <el-table-column prop="abstract" sortable label="排课摘要" align="center" width="150">
                </el-table-column>
                <el-table-column prop="number" sortable label="培训课码" align="center" width="150">
                </el-table-column>
                <el-table-column prop="class_name" sortable label="培训班名称" align="center" width="180">
                </el-table-column>
                <el-table-column prop="course_date" sortable label="上课日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="course_time" sortable label="上课时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="course_duration" sortable label="上课时长(hr)" align="center" width="150">
                </el-table-column>
                <el-table-column prop="remark" sortable label="备注说明" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 签到主表 -->
        <div class="" v-if="tab==2" :key="2">
            <el-table :data="signData" border stripe style="width:97.9%;flex: 1;" height="650">
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" sortable label="审核状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="单号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="班级编号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="班级名称" align="center" width="300">
                </el-table-column>
                <el-table-column prop="" sortable label="授课老师" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="课次" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="上课日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="上课时间" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" sortable label="本班人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="签到人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="试学人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="补课人数" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="出勤率(%)" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="工作量-1" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="工作量-2" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" sortable label="上课教室" align="center" width="120">
                </el-table-column>
            </el-table>
        </div>
        <!-- 相关收款表 -->
        <!-- <div class="" v-if="tab==3" :key="3">
            <el-table :data="relevantData" border stripe style="width:97.9%;flex: 1;" height="650">
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column prop="" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学员编号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="学生姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="回款日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="结算方式" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="培训费" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="业务类型" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="所属科目" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="摘要" align="center" width="300">
                </el-table-column>
            </el-table>
        </div> -->
        <!-- 审核日志 -->
        <div class="" v-if="tab==3" :key="3">
            <el-table :data="journalData" border stripe style="width:97.9%;flex: 1;" height="700">
                <el-table-column type="index" label="序号" align="center" width="50">
                </el-table-column>
                <el-table-column prop="arrange_number"  label="安排单号" align="center" width="120">
                </el-table-column>
                <el-table-column prop="arrange_time"  label="安排日期" align="center" width="120">
                </el-table-column>
                <el-table-column prop="approve_time"  label="审核时间" align="center" width="200">
                </el-table-column>
                <el-table-column prop="approve_name"  label="审核人" align="center" width="120">
                </el-table-column>
                <el-table-column prop="before_approve"  label="审核前状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="after_approve"  label="审核后状态" align="center" width="120">
                </el-table-column>
                <el-table-column prop="approve_comments"  label="审核意见" align="center" width="300">
                </el-table-column>
            </el-table>
        </div>
        <!-- 审核意见 -->
        <div class="" v-if="tab==4" :key="4">
            <div class="" style="margin-left: 21px;">
                <div class="" style="margin-bottom: 21px;">
                    班级名称：{{className}}
                </div>
                <el-input v-model="opinion" type="textarea" class="textArea-width" rows="4"></el-input>
            </div>
            <!-- 底部 提交与取消 -->
            <div slot="footer" class="dialog-footer">
                <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="determine">确 认</el-button>
                <el-button class="cancel" @click="cancel">取 消</el-button>
            </div>
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
             @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!==0&&tab!==3&&tab!==4">
        </el-pagination>
    </div>
</template>

<script>
   export default{
       data() {
           return{
              tabBtn:["单据说明","排课表","签到主表","审核日志","审核意见"],
              tab:0,//代表下标
              // billData:[],//单据说明数据
              form:{
                  billData:""
              },
              scheduleData:[],//排课表数据
              signData:[],//签到主表数据
              relevantData:[],//相关收款表数据
              journalData:[],//审核日志数据
              opinion:"",//审核意见
              className:"",//班级名称
              currentPage: 1,
              currentLimit: 20, //条数
              total: 0,
              bool:false
           }
       },
       created() {
           this.getList()
       },
       methods:{
            getList(){
                if(this.tab==0){//单据说明
                    this.$request({
                        url:'/api/class_/document_remark',
                        method:'POST',
                        data:{
                            id:this.$route.query.class_id,
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'单据说明')
                            this.form.billData=res.data.document_remark
                            
                        }
                    })
                }else if(this.tab==1){//排课表
                    this.$request({
                        url:'/api/class_/scheduleList',
                        method:'POST',
                        data:{
                            class_id:this.$route.query.class_id,
                            page:this.currentPage,
                            limit:this.currentLimit
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'排课表')
                            this.scheduleData=res.data.list
                            this.total=res.data.count
                        }
                    })
                }else if(this.tab==2){//签到主表
                    
                }else if(this.tab==3){//审核日志
                    this.$request({
                        url:'/api/class_/approve_log',
                        method:'POST',
                        data:{
                            id:this.$route.query.class_id
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'审核日志')
                            this.journalData=res.data
                        }
                    })
                }else if(this.tab==4){//审核意见
                    this.$request({
                        url:'/api/class_/detail',
                        method:"POST",
                        data:{
                           id:this.$route.query.class_id,
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res)
                            this.className=res.data.class_name
                        }
                    })
                }   
                 
            },
            toTab(index){
                this.tab=index    
                this.getList()
            },
            goBack() { //返回
                this.$router.go(-1);
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                console.log(`当前页: ${val}`);
            },
            determine(){//确认
                if(this.bool){
                    return
                }
                this.bool=true
                setTimeout(() => {
                    this.bool=false
                }, 1500)
                this.$request({
                  url:'/api/class_/approve_comment',
                  method:"POST",
                  data:{
                     id:this.$route.query.class_id,
                     approve_comments:this.opinion
                  }
              }).then(res=>{
                  if(res.code==1){
                      // console.log(res)
                      this.$message({
                          message: '提交成功',
                          type: 'success'
                      })
                      this.opinion=''
                  }else{
                      this.$message({
                          message: res.msg,
                          type: 'error'
                      })
                  }
              })
            },
            cancel(){//取消
                this.opinion=''
            }
       }
   } 
</script>

<style scoped="scoped">
    .ClassRegisterMore{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* tab按钮 */
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin-left: 21px;
       font-size: 14px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background:#29726C;
        color: #FFFFFF;
    }
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        background-color: #fff;
        color: #333;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin:20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-top: 50px;
    }
    
    .cancel:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
